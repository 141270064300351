$fa-font-path: "../webfonts";
@import "normalize";
@import "../fonts/fontawesome/scss/fontawesome.scss";
@import "../fonts/fontawesome/scss/solid.scss";
$dark: #333333;
$grey: #c8c8c8;
$yellow: #feda4a;

@keyframes loading {
  0% {
    background-position: 0;
  }
  50% {
    background-position: 100%;
  }
  100% {
    background-position: 0;
  }
}
@keyframes spin {
  from {
    transform:rotate(0deg);
  }
  to {
    transform:rotate(360deg);
  }
}
.spin{
  animation-name: spin;
  animation-duration: 2000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}
* {
  box-sizing: border-box;
}
.text-center{
  text-align: center;
}

h1{
  font-size:25px;
}
h2{
  font-size:16px;
}

.App {
  min-height:100vh;
  font-family: "PT Sans", sans-serif;
  background:$dark;
  .box{
    margin:5px;
    border-radius:5px;
    box-shadow: 1px 1px 3px 0px rgba(0,0,0,0.35);
    background:white;
    overflow: hidden;
  }

  #searchBarWrap{
    display:flex;
  }
  #searchBar{
    width:100%;
  }
  .MuiOutlinedInput-input{
    padding-right:40px!important;
    min-width:112px;
  }

  .margin{
    margin:5px;
  }
  .loading{
    background-image: linear-gradient(45deg, #c7c9c7, rgb(87, 92, 92) 0%, #c7c9c7);
    background-size: 300%;
    animation: loading 2s ease infinite;
    border-radius: 5px;
    margin-bottom: 5px;
    height:50px;
    display:flex;
    &.light-gray{
      animation: loading 2s ease infinite reverse;
      //background-image: linear-gradient(45deg, #e6e7e6, rgb(151, 155, 155) 0%, #e6e7e6);
    }
  }
  #pwaInstall{
    display:none;
    flex-direction: column;
    text-align: center;
    background: $yellow;
    color: $dark;
    font-weight: bold;
    justify-content: center;
    box-shadow: -15px 0px 15px -15px rgba(0,0,0,0.75);
    cursor:pointer;
    padding:10px;
    i{
      font-size: 25px;
      padding: 5px;
    }
  }

  #home{
    position: fixed;
    bottom: 0;
    left: 0;
    right:0;
    display:flex;
    flex-wrap: wrap;
    background:rgba($dark,0.95);
    color:$yellow;
    width:calc(100% - 10px);
    margin:5px auto;
    max-width:380px;
    max-height:calc(100vh - 70px);
    overflow: hidden;
    transition:all 1s;
    a{
      color:$yellow;
    }
    &.hide{
      transform: translateY(150%);
    }
    .preview-wrap{
      display:flex;
    }
    .preview-inner-wrap{
      display:flex;
      cursor: pointer;
    }
    #back-drop{
      position:fixed;
      height:100%;
      width:100%;
      top:0;
      left:0;
      display:none; 
      z-index:-1;
      &.show{      
        display:block;
      }
    }
    .infoButton{
      position:absolute;
      top:0;
      left:0;
      font-size:14px;
      width:22px;
      background:$yellow;
      color:$dark;
      text-align: center;
      display:block;
      padding:5px;
      border-radius:0 0 5px 0;
      cursor:pointer;
    }
    #extraInfo{
      display:flex;
      flex-direction: column;
      max-height: 0;
      padding:0 10px;
      transition:padding 0.3s, max-height 1s;
      &.show{
        overflow: auto;
        max-height: 410px;
        padding:10px;
      }
      h2{
        font-size:16px;
        font-weight:bold;
        margin-bottom:0;
        margin-top:10px;
      }
      p{
        font-size:14px;
        margin:5px 0;
      }
    }
    .intro{
      padding:10px;
    }
    h1{
      margin:0;
      font-size:18px;
    }
    h2{
      margin:5px 0;
      font-weight: normal;
      font-size:13px;
    }
    .logo{
      max-width: 80px;
      margin: 0 auto;
      align-self: center;
      padding:5px 5px 5px 10px;
    }
  }

  #header {
    display:flex;
    flex-direction: column;
    flex:1;
    position:relative;
    z-index:1;

    #searchBar {
      position:relative;
      overflow:hidden;
      input{
        margin-bottom:0;
        border: 0;
        padding: 15px;
        font-size: 18px;
        font-weight: 500;
        outline: 0;
        width: 100%;
        -webkit-transition: all 0.3s ease-in-out;
        -moz-transition: all 0.3s ease-in-out;
        -ms-transition: all 0.3s ease-in-out;
        -o-transition: all 0.3s ease-in-out;
        transition: all 0.3s ease-in-out;
        -webkit-transform: translateY(0%);
        -moz-transform: translateY(0%);
        -ms-transform: translateY(0%);
        -o-transform: translateY(0%);
        transform: translateY(0%);
        &::placeholder {
          color: rgba($dark, 0.5);
          opacity: 1;
        }
      }
      #resetSearch{
        color: $dark;
        position:absolute;
        top: 0;
        right: 0;
        padding: 18px 10px 18px 5px;
        z-index:10;
        background:white;
        box-shadow: 0 0 15px 15px white;
      }
    }

    #searchResults {
      padding: 0;
      list-style: none;
      font-weight: 500;
      cursor: pointer;
      max-height: 153px;
      overflow-x: auto;
      width: calc(100% - 10px);
      &:empty{
        margin:0;
      }
      li {
        padding: 15px;
        font-size:18px;
        border-bottom: 1px solid rgba($dark, 0.5);
        color:$dark;
        &:last-child {
          border-bottom: 1px solid transparent;
        }
      }
      .noResult{
        background:white;
        color:$dark;
        text-align: center;
        padding: 30px 15px;
        font-size: 16px;
        border-bottom:0!important;
        .fa{
          font-size: 30px;
          margin-bottom: 10px;
        }
      }
    }
  }

  #main{
    display:flex;
    flex-direction: column;
    flex:1;
    position:relative;
    z-index:1;

    .HarborFilter{
      display: flex;
      align-items: center;
      justify-content: space-around;
      cursor: pointer;
      -webkit-tap-highlight-color: transparent;
      span{
        flex: 1;
        padding:15px;
        text-align:center;
        max-width:50%;
        transition: all 0.15s ease-in-out;
        &::after {
          transition: all 0.15s ease-in-out;
          content: "\f0d7";
          font-family: "Font Awesome 5 free";
          position: absolute;
          bottom:0;
          left: 0;
          right: 0;
          text-align: center;
          opacity:0;
        }
      }
      .active{
        font-weight:bold;
        position: relative;
        box-shadow: 0px 0px 10px -3px inset rgba(0, 0, 0, 0.9);
        &::after {
          opacity:1;
        }
      }
    }

    .Deviation{
      border-left: 5px solid #ff4e42;
      padding: 10px;
      margin: 5px;
      background: #eee;
    }

    .Departures{
      padding: 0;
      list-style: none;
      overflow:auto;
      .FerryRouteName{
        width: 100%;
        text-align: center;
        display: inline-block;
        background: #ececec;
        padding: 5px 0;
        position:relative;
        overflow: hidden;
        i{
          padding: 6px 10px;
          position: absolute;
          top: 0;
          right: 0;
          cursor:pointer;
        }
      }
      li{
        padding:12px 15px;
        border-bottom: 1px solid $grey;
        display: flex;
        align-items: center;
        justify-content: space-between;
        &:last-child {
          border-bottom: 1px solid transparent;
        }
        .ferryDepartureInfo{
          display:flex;
          align-items:center;
        }
        .ferryIcon{
          background: $dark;
          display: inline-block;
          width: 30px;
          height: 30px;
          padding:5px;
          text-align: center;
          border-radius: 100px;
          img{
            max-width:100%;
          }
        }
        .ferryFromTo{
          padding: 0 10px;
          font-size: 12px;
          i{
            font-size: 0.6em;
            display: inline-block;
            padding: 5px;
            vertical-align: text-top;
          }
        }
        .ferryDepartureDateTime{
          text-align: right;
          position:relative;
          .time, .date{
            display:block;
          }
          .time{
            font-weight: bold;
            font-size:24px;
          }
          .date{
            font-size:11px;
            position:absolute;
            right:0;
            white-space: nowrap;
          }
        }

      }
    }

    #mapOverlay{
      position: fixed;
      height: 100vh;
      width: 100vw;
      z-index: -1;
      left: 0;
    }
  }

  .Map{
    position: fixed;
    top: 0px;
    left: 0px;
    bottom: 0px;
    right: 0px;
    z-index: 0;
    transition:filter 0.8s ease-in-out;
    .gm-style{
      background: $dark;
    }
    &.blur{
      filter: blur(5px);
    }
  }

  .FerryRouteInformation{
    margin-top:50px;
    img{
      width:100%;
      object-fit: cover;
      object-position: top;
      max-height: 180px;
    }
    .FerryRouteText{
      padding: 10px;
      h1{
        margin: 0;
      }
      h2{
        margin-top:0.25em;
      }
    }
  }

}

@media screen and (min-width: 768px){

  .App {
    #header, #main {
      max-width:380px;
      margin:0 auto;
    }
  }
}

@media all and (display-mode: standalone) {
  body {
    .App {
      #home{
        display:none;
      }
    }
  }
}